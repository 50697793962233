//
// Burger Icon CSS
//

.burger-icon {
  position: absolute;
  top: 22px;
  right: 35px;
  cursor: pointer;
  z-index: 99;
  background: none;
  border: none;
  padding: 5px 5px 15px 5px;
  //display: flex;

  span.icon {
    display: block;
    position: relative;
    height: 3px;
    background-color: $grey;
    width: 36px;
    margin-top: 11px;
    transition-delay: 0.4s, 0.5s;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $grey;
      content: "";
      transition-duration: 0.3s, 0.3s;
      transition-delay: 0.3s, 0s;
    }

    &::before {
      top: -11px;
      transition-property: top, transform;
    }

    &::after {
      bottom: -11px;
      transition-property: bottom, transform;
    }
  }

  span.text {
    @include visually-hidden;
  }

  &:focus,
  &:focus-visible {
    outline: 2px dotted $grey;
    border-radius: 5px;
  }

  @media only screen and (min-width: $size-L2) {
    display: none;
  }


  @media only screen and (max-width: $size-S) {
    right: 15px;
  }
}

.nav-main.active {
  .burger-icon {
    span.icon {
      background: none !important;
      //transition-delay: 0.3s, 0.3s;
      transition-delay: 0ms;

      &::before {
        top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.3s;
      }
      &::after {
        bottom: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.3s;
      }
    }
  }
}