//
// Default Project Settings
//

$maxPageWidth: 1920px;
$maxPageInnerWidth: 1600px;
$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Pacifico', sans-serif;


// font sizes
$font-size-XXL: 54;
$font-size-XL: 40;
$font-size-L: 28;
$font-size-M2: 20;
$font-size-M: 18;
$font-size-S: 16;
$font-size-XS: 14;


// Spacing / gaps
$gap-XXL: 80px;
$gap-XL: 60px;
$gap-L: 40px;
$gap-M2: 30px;
$gap-M: 20px;
$gap-S: 10px;
$gap-XS: 5px;


// General Breakpoints
$size-XL2: 1600px;
$size-XL: 1400px;
$size-L2: 1200px;
$size-L: 1000px;
$size-M4: 900px;
$size-M3: 800px;
$size-M2: 760px;
$size-M: 700px;
$size-S3: 600px;
$size-S2: 500px;
$size-S: 420px;