//
// CE Hero Slider CSS
//

.ce-hero_slider {
  position: relative;
  color: var(--primary-color);

  &-slides {
    position: relative;
  }

  figure {
    position: relative;

    img {
      display: block;
      width: 100%;
      max-width: none;
    }
  }

  .vohburg-wave {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

}