//
// CE Text CSS
//

.ce-text {

  @media only screen and (min-width: $size-M) {
    // 2 cols 50/50
    .ce-layout-two-cols {

      .bodytext {
        column-count: 2;
        column-gap: 60px;
      }
    }
  }
}