//
// CE Image Gallery CSS
//

.ce-image_gallery {

  h2 {
    text-align: center;
  }
}

.ce-image-gallery-images {
  display: flex;
  flex-flow: row wrap;

  figure {
    width: 100%;
    overflow: hidden;

    @media only screen and (min-width: $size-S) {
      width: calc(100% / 2);
    }

    @media only screen and (min-width: $size-S3) {
      width: calc(100% / 3);
    }

    @media only screen and (min-width: $size-M2) {
      width: calc(100% / 4);
    }

    @media only screen and (min-width: $size-L2) {
      width: calc(100% / 5);
    }

    img {
      transition: transform 200ms ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }

  img {
    display: block;
  }
}