//
// CE Image CSS
//

.ce-images {

}

.ce-images-images {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  figure {
    max-width: 700px;
  }

  // Grid 2
  &.grid-2 {

    figure {
      margin-top: $gap-M;

      &:first-child {
        margin-top: 0;
      }
    }


    @media only screen and (min-width: $size-S2) {
      display: flex;
      overflow: hidden;
      margin: -$gap-S;

      figure {
        width: calc(100% / 2);
        max-width: 500px;
        padding: $gap-M;
        margin-top: 0;
      }
    }
  }


  // Grid 3
  &.grid-3 {

    figure {
      margin-top: $gap-M;
      max-width: 500px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media only screen and (min-width: $size-M) {
      display: flex;
      overflow: hidden;
      margin: -$gap-S;

      figure {
        width: calc(100% / 3);
        padding: $gap-M;
        margin-top: 0;
      }
    }

  }

}