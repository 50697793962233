//
// CE News Grid CSS
//

.ce-news {

  header {
    position: relative;
    background: var(--primary-color);
    padding-bottom: 13%;

    h1 {
      margin: 0;
      padding-bottom: $gap-L;
      color: $white;
      text-align: center;
      line-height: 1.6;
    }
  }
}

.ce-news-items {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  margin: -$gap-L;
  margin-top: -25%;

  article {
    padding: $gap-L;
    max-width: 500px;
  }

  img {
    border-radius: 6%;
  }

  @media only screen and (min-width: $size-M2) {
    margin: -$gap-S;
    margin-top: -15%;

    article {
      width: calc(100% / 3);
      padding: $gap-S;
    }

  }

  @media only screen and (min-width: $size-L) {
    margin: -$gap-L;
    margin-top: -15%;

    article {
      padding: $gap-L;
    }

  }

}

.ce-news-item {
  text-align: center;

  .more-link {
    display: inline-block;
    margin-top: 30px;
    border-radius: 24px;
    border: 2px solid var(--secondary-color);
    text-transform: uppercase;
    padding: 8px 20px;
    text-decoration: none;
    @include font-size($font-size-XS);
    font-weight: 700;
    transition: background-color 200ms;
    white-space: nowrap;

    &:hover {
      background: var(--secondary-color);
      color: $white;
    }
  }
}

.ce-news-item-text {
  padding: 0 30px;
  hyphens: auto;
  @include font-size($font-size-S);

  h2 {
    margin-top: 0.4em;
    line-height: 1.4;
    @include font-size($font-size-M2);
  }

  @media only screen and (min-width: $size-M2) {
    padding: 0 10px;
  }

  @media only screen and (min-width: $size-L) {
    @include font-size($font-size-M);

    h2 {
      @include font-size($font-size-L);
    }
  }

  @media only screen and (min-width: $size-L2) {
    padding: 0 30px;
  }

}