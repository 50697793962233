//
// CE Text Image CSS
//

.ce-text_image {

  // ce-image-position-25 = image right
  // ce-image-position-26 = image left

  header {

    @media only screen and (min-width: $size-M) {
      margin-bottom: $gap-M2;
    }
  }

  .ce-text-image-image {
    display: flex;
    margin-bottom: $gap-M2;

    @media only screen and (min-width: $size-M) {
      margin-bottom: 0;
    }
  }

  img {
    display: block;
  }

  figure {
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;

    figcaption {
      padding: $gap-XS;
    }
  }

  // set color versions
  .ce-boxed {

    .ce-text-image-text {
      padding: $gap-L;
      color: $white;

      @media only screen and (max-width: $size-M) {
        padding-top: $gap-S;
      }

      h2,
      h3 {
        color: $white;
      }
    }

    .more-link {
      display: flex;
      justify-content: flex-end;

      a {
        display: inline-block;
        margin-top: 20px;
        border-radius: 24px;
        border: 2px solid $white;
        color: $white;
        text-transform: uppercase;
        padding: 8px 20px;
        text-decoration: none;
        @include font-size(14);
        font-weight: 700;
        transition: background-color 200ms;
        white-space: nowrap;

        &:hover {
          background: rgba($white, .2);
        }
      }
    }

  }

  // green
  &.ce-color-green {

    .ce-boxed .ce-text-image-main {
      background: $green;
    }
  }

  // blue light
  &.ce-color-blue-light {

    .ce-boxed .ce-text-image-main {
      background: $blue;
    }
  }

  // image left / right
  .ce-image-position-25,
  .ce-image-position-26 {

    @media only screen and (max-width: $size-M) {
      display: block;

      .ce-text-image-text {
        margin-bottom: $gap-M;
      }
    }

  }

  @media only screen and (min-width: $size-M) {
    .ce-text-image-image {
      width: 40%;
    }
    .ce-text-image-text {
      width: 60%;
    }

  }


  // image left
  .ce-image-position-26 {
    @media only screen and (min-width: $size-M) {

      .ce-text-image-image {
        padding-right: $gap-M2;
      }

      .ce-text-image-text {
        padding-left: $gap-M2;
      }
    }
  }


  // image right
  .ce-image-position-25 {
    @media only screen and (min-width: $size-M) {

      .ce-text-image-image {
        order: 2;
        padding-left: $gap-M2;
      }

      .ce-text-image-text {
        padding-right: $gap-M2;
      }
    }
  }


}