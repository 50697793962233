//
// Nav Main CSS
//

.nav-main {

  ul {
    @include ul-reset;
  }

  a {
    text-decoration: none;
  }

  // mobile
  @media only screen and (max-width: $size-L2) {

    a {
      display: block;
      @include font-size($font-size-S);
    }

    > ul {
      //display: none;
      position: absolute;
      top: 80px;
      right: 0;
      z-index: 9;
      width: 320px;
      //background: rgba(var(--primary-color), .9);
      background: var(--primary-color);
      transform: translateX(100%);
      transition: all 500ms;
      box-shadow: -2px 2px 5px rgba(0,0,0,.3);

      > li {
        border-top: 1px solid $white;
        position: relative;

        > a {
          display: block;
          padding: 10px 20px;
          white-space: nowrap;
          font-weight: 700;

          &:hover,
          &.focus,
          &:focus-visible {
            background: rgba($black, .4);
          }
        }

        &:first-child {
          border: none;
        }

        ul {
          display: none;
        }
      }

    }

    &.active > ul {
      transform: translateX(0);
      //display: block;
    }

    a {
      color: $white;
    }

  }

  @media only screen and (max-width: $size-S) {
    > ul {

      > li {
        .open-sub {
          right: 20px;
        }
      }
    }
  }



  // desktop
  @media only screen and (min-width: $size-L2) {
    display: flex;

    ul {
      display: flex;
      margin: auto;

      ul {
        display: none;
      }
    }

    a {
      text-decoration: none;
      color: $grey;
    }

    li {
      position: relative;

      .open-sub {
        display: none;
      }

      &:hover {
        ul {
          display: block !important;
        }
      }
    }


    > ul {
      display: flex;

      > li {
        display: flex;
        padding: 0 4px;

        > a {
          display: flex;
          padding: 6px 15px;
          align-items: center;
          font-weight: 700;
          border-radius: 24px;
          text-transform: uppercase;

          &:hover,
          &.active {
            background: var(--secondary-color);
            color: $white;
          }
        }


        // level 2
        ul {
          display: none;
          position: absolute;
          top: 70px;
          left: 0;
          background: rgba($black, .9);
          padding: 0;

          li {
            border-top: 1px solid $white;

            a {
              display: block;
              padding: 8px 20px;
              white-space: nowrap;

              &:hover {
                background: rgba($black, .6);
              }
            }

            &:first-child {
              border: none;
            }
          }
        }

      }

    }

  }

  /*
  @media only screen and (min-width: $size-L) {

    > ul {
      width: 100%;
      max-width: $maxPageInnerWidth;
      padding: 0 40px;
      margin: auto;
    }

  }
  */

}

// on root pages make first menu item active which is a shortcut only
@media only screen and (min-width: $size-L2) {

  .pid-0 {
    .nav-main > ul > li:first-child > a {
      background: var(--secondary-color);
      color: $white;
    }
  }

}