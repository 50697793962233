//
// Main CSS
//

main {
  min-height: 300px;


  @media only screen and (min-width: $size-M) {

  }

}