//
// Project Color Definitions
//

$primary-spatzennest: #A31E33;
$secondary-spatzennest: #FD4D40;

$primary-fuchsbau: #e64415;
$secondary-fuchsbau: #101d52;

$primary-sonnenschein: #ee6e00;
$secondary-sonnenschein: #ee6e00;

$primary-st-martin: #d20049;
$secondary-st-martin: #0d9eb0;

$primary-rappelkiste: #026920;
$secondary-rappelkiste: #014278;

:root {
  --primary-color: #{$primary-spatzennest};
  --secondary-color: #{$secondary-spatzennest};
}

.fuchsbau {
  --primary-color: #{$primary-fuchsbau};
  --secondary-color: #{$secondary-fuchsbau};
}

.sonnenschein {
  --primary-color: #{$primary-sonnenschein};
  --secondary-color: #{$secondary-sonnenschein};
}

.st-martin {
  --primary-color: #{$primary-st-martin};
  --secondary-color: #{$secondary-st-martin};
}

.rappelkiste {
  --primary-color: #{$primary-rappelkiste};
  --secondary-color: #{$secondary-rappelkiste};
}


// General Colors
$green: #94B81A;
$green-light: #E4ECC5;

$blue: #A3D0ED;
$blue-light: #E6F3FB;

$grey-dark: #333333;
$grey: #666666;
$grey-light: #d0d0d0;
$grey-super-light: #F9F8F6;

$white: #FFFFFF;
$black: #000000;