//
// Nav Footer CSS
//

.nav-footer {

  ul  {
    @include ul-reset;

    li {

      a {
        text-decoration: none;
        color: $white;
        @include font-size(16);
      }
    }
  }

}