//
// CE Hero Image CSS
//

.ce-hero_image {
  position: relative;

  figure {
    position: relative;
  }

  > img,
  figure > img {
    display: block;
    max-width: none;
    width: 100%;
  }

  figcaption {
    padding: 20px 0;
    background: rgba($white, .7);

    @media only screen and (min-width: $size-M) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @media only screen and (max-width: $size-S2) {
      .flex-row {
        flex-flow: column;
      }
    }

    h1 {
      margin: 0;
    }

    .flex-row {
      justify-content: space-between;
      align-items: center;
    }
  }

  .ce-hero-image-caption-text {
    padding-right: $gap-M;
    hyphens: auto;
  }

  .ce-hero-image-caption-title {
    @include font-size($font-size-XXL);
    color: var(--secondary-color);
    font-family: $secondary-font;
    line-height: 1.4;
    margin-bottom: 0.4em;
    hyphens: auto;
  }

  .more-link {
    display: inline-block;
    margin-top: 20px;
    border-radius: 24px;
    border: 2px solid var(--secondary-color);
    text-transform: uppercase;
    padding: 8px 20px;
    text-decoration: none;
    @include font-size($font-size-XS);
    font-weight: 700;
    transition: background-color 200ms;
    background: var(--secondary-color);
    color: $white;
    white-space: nowrap;

    &:hover {
      background: $white;
      color: var(--secondary-color);
    }
  }

  @media only screen and (max-width: $size-L) {
    @include font-size($font-size-S);

    .ce-hero-image-caption-text {
      @include font-size($font-size-S);
    }

    .ce-hero-image-caption-title {
      @include font-size($font-size-L);
    }
  }

}