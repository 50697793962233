//
// Header CSS
//

.page-header {
  position: relative;
  background: $grey-super-light;
  z-index: 5;

  .flex-row {
    justify-content: space-between;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      display: block;
    }

    a {
      &:focus-visible {
        outline: 2px dotted;
        outline-offset: 5px;
        border-radius: 5px;
      }
    }
  }

  .logo-vohburg {
    display: flex;
    align-items: center;

    a {
      &:focus-visible {
        outline: 2px dotted;
        outline-offset: 5px;
        border-radius: 5px;
      }
    }

    img {
      display: block;
      width: 150px;
    }

    @media only screen and (max-width: $size-L2) {
      padding-right: 80px;

      img {
        width: 100px;
      }
    }

    @media only screen and (max-width: $size-S) {
      padding-right: 60px;
    }
  }
}

.spatzennest {
  .logo {
    width: 240px;
  }
}

.st-martin {
  .logo {
    width: 240px;
  }
}

.sonnenschein {
  .logo {
    width: 135px;
  }
}

.rappelkiste {
  .logo {
    width: 280px;
  }
}

@media only screen and (max-width: $size-L2) {
  .spatzennest {
    .logo {
      width: 110px;
    }
  }

  .st-martin {
    .logo {
      width: 110px;
    }
  }

  .sonnenschein {
    .logo {
      width: 80px;
    }
  }

  .rappelkiste {
    .logo {
      width: 140px;
    }
  }

  .fuchsbau {
    .logo {
      width: 130px;
    }
  }
}

.page-header-main {
  display: flex;
  height: 80px;

  @media only screen and (min-width: $size-L2) {
    height: 160px;
  }
}

.page-header-bottom-bar {
  display: flex;
  align-items: center;
  background: var(--primary-color);
  color: $white;
  height: 50px;
  @include font-size($font-size-XS);

  a {
    display: flex;
    align-items: center;
    height: 100%;
    color: $white;
    text-decoration: none;
    line-height: 28px;

    &:focus-visible {
      outline: 2px dotted;
      outline-offset: 5px;
      border-radius: 5px;
    }
  }

  @media only screen and (max-width: $size-M4) {
    .email a {
      width: 1px;
      overflow: hidden;
    }
  }

  @media only screen and (min-width: $size-M) {
    br {
      display: none;
    }
  }

  @media only screen and (min-width: $size-M4) {

    @include font-size($font-size-M);
    font-weight: 700;
  }

  @media only screen and (max-width: $size-S2) {

    .phone a {
      width: 1px;
      overflow: hidden;
    }
  }

  .email a {
    background: url("../Images/icon_email.svg") no-repeat left center;
    background-size: 28px;
    padding-left: 40px;
  }

  .phone a {
    background: url("../Images/icon_phone.svg") no-repeat left center;
    background-size: 28px;
    padding-left: 40px;
  }

  .time {
    display: flex;
    align-items: center;
    background: url("../Images/icon_time.svg") no-repeat left center;
    background-size: 28px;
    padding-left: 40px;
    min-height: 28px;
  }
}