//
// CE Colors CSS
//

// green
.ce-color-green {
  background: $green-light;
}

// blue light
.ce-color-blue-light {
  background: $blue-light;
}

// blue
.ce-color-blue {
  background: $blue;
}

// grey
.ce-color-grey {
  background: $grey-super-light;
}