//
// CE Cards CSS
//

.ce-cards {

  ul {
    @include ul-reset;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: -$gap-S;

    li {
      width: 100%;
      max-width: 365px;
      padding: $gap-S;
    }
  }

  .ce-cards-item {
    background: $white;
    border-radius: 12px;

    .ce-cards-image {
      position: relative;
      padding-bottom: 100%;
      overflow: hidden;
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      border-radius: 12px 12px 0 0;
      object-fit: cover;
    }
  }

  .ce-cards-text {
    padding: 20px;
    text-align: center;
    @include font-size(16);

    h3 {
      margin-top: 0;
      margin-bottom: 0.6em;
      line-height: 1.4;
    }
  }


  // Grid Variants
  .ce-grid-2 {
    @media only screen and (min-width: $size-M) {
      li {
        width: calc(100% / 2);
      }
    }
  }

  .ce-grid-3 {
    @media only screen and (min-width: $size-M) {
      li {
        width: calc(100% / 3);
      }
    }
  }

  .ce-grid-4 {
    @media only screen and (min-width: $size-M) {
      li {
        width: calc(100% / 2);
      }
    }

    @media only screen and (min-width: $size-L) {
      li {
        width: calc(100% / 4);
      }
    }
  }

}