//
// Glide Slider
//

@import 'variables';

.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  &#{$se}arrow {
    position: absolute;
    display: block;
    top: 40%;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    padding: 9px 20px;
    background: url('../Images/arrow_right.svg') no-repeat $grey center center;
    background-size: 32px;
    width: 50px;
    //border: 2px solid rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 4px;
    //box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    //text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
    opacity: .8;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    //transform: translateY(-50%);
    line-height: 1;
    overflow: hidden;
    text-indent: -9999px;

    &:focus { outline: none; }
    &:hover { border-color: white; opacity: 1; }

    &#{$sm}left {
      left: 2em;
      transform: scaleX(-1);
    }

    &#{$sm}right {
      right: 2em;
    }

    &#{$sm}disabled {
      opacity: 0.33;
    }
  }

  &#{$se}bullets {
    position: absolute;
    z-index: 2;
    bottom: 2em;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
  }

  &#{$se}bullet {
    background-color: rgba(255, 255, 255, 0.5);
    width: 9px;
    height: 9px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 0.25em;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &#{$sm}active {
      background-color: white;
    }
  }

  &#{$sm}swipeable {
    cursor: grab;
  }

  &#{$sm}dragging {
    cursor: grabbing;
  }
}